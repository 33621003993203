<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('regions.edit')">
        <actions
          slot="actions"
          crud-links="regions"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <tabs-container :tabs="tabs">
          <template v-slot:data>
            <data-form
              :region="region"
              :loading="loading"
              @submit="submit"
            />
          </template>
          <template v-slot:methods>
            <change-methods
              :region="region"
              :loading="loading"
              @submit="changeMethods"
            />
          </template>
          <template v-slot:managers>
            <change-managers
              :edit="true"
              :region="region"
              :loading="loading"
            />
          </template>
          <template v-slot:modules>
            <change-modules
              :edit="true"
              :region="region"
              :loading="loading"
            />
          </template>
        </tabs-container>
      </va-card>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')
const ChangeMethods = () => import(/* webpackPrefetch: true */ './ChangeMethods')
const ChangeManagers = () => import(/* webpackPrefetch: true */ './ChangeManagers')
const ChangeModules = () => import(/* webpackPrefetch: true */ './ChangeModules')

export default {
  name: 'regions-edit',
  components: {
    Actions,
    DataForm,
    TabsContainer,
    ChangeMethods,
    ChangeManagers,
    ChangeModules,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      region: {},
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tabs () {
      const t = [
        { title: this.$t('regions.tabs.data.title'), name: 'data' },
      ]
      if (this.currentUser.can('EvangelisticMethods', 'index')) {
        t.push({ title: this.$t('regions.tabs.evangelistic_methods.title'), name: 'methods' })
      }
      if (this.currentUser.can('RegionManagers', 'index')) {
        t.push({ title: this.$t('regions.tabs.region_managers.title'), name: 'managers' })
      }
      if (this.currentUser.can('RegionModules', 'index')) {
        t.push({ title: this.$t('regions.tabs.region_modules.title'), name: 'modules' })
      }

      return t
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `regions/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const regionId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(regionId))
      } catch (err) {
        // console.log('Error fetching region data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.region = { ...u.data.data }
    },
    async submit (region) {
      this.loading = true
      try {
        await this.$http.put(this.routeBuilder(region.id), region)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
    async changeMethods (data) {
      console.log('Methods', data)
      this.loading = true
      try {
        await this.$http.put(`regions/methods/${data.id}`, data)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
